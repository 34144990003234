/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.firebase-emulator-warning {
  display: none !important;
}

.confirmAlert .danger {
  color: var(--ion-color-danger);
}

.routemap-stop {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
}
.routemap-stop-label {
  fill: #1d1d1d;
}
@media (prefers-color-scheme: dark) {
  .routemap-stop-label {
    fill: white;
  }
  .routemap-stop {
    stroke-width: 0px;
  }
}
